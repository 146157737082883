@import './variables.scss';
@import './helpers.scss';
@import './job-logs.scss';
@import './modals.scss';

/** SITE-WIDE STYLES **/
#app {
  text-align: center;
  color: $secondary;
  .btn {
    color: white;
    background-color: $primary;
  }
  .spaced {
    margin-left: 20px;
    width: 170px;
  }
}

/** NAVBAR STYLES **/
.navbar {
  background-color: $accent;
  padding-bottom: 0;
  img {
    height: 4rem;
    margin-left: 2rem;
  }
  .nav-item {
    font-size: 1.25rem;
    padding: 0.5rem;
    cursor: pointer;
    .nav-link {
      height: 100%;
    }
    &.active {
      font-weight: 700;
      border-bottom: 0.33rem solid $primary;
      margin-bottom: -0.875rem;
    }
  }
}

/** LANDING PAGE STYLES **/
#landing {
  .login {
    width: 20rem;
  }
}

/** FORM FIELD STYLES **/
.error {
  color: $danger;
}
.message {
  color: $info;
}
.warning {
  color: $warning;
}

@import "../../node_modules/react-table-6/react-table.css";
@import "../../node_modules/bootstrap/scss/bootstrap";
