@import './variables.scss';

.primary {
  color: $primary !important;
  &-bg {
    background-color: $primary;
  }
}
.secondary {
  color: $secondary !important;
  &-bg {
    background-color: $secondary;
  }
}
.accent {
  color: $accent !important;
  &-bg {
    background-color: $accent;
  }
}