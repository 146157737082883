@import "./variables.scss";

/** JOB LOGS PAGE STYLES **/
#job-logs {
  .ReactTable {
    .rt-th {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      white-space: unset;

      input {
        background-image: url("./images/icons/search-icon.png");
        background-size: 0.85rem 0.85rem;
        background-position: calc(100% - 0.25rem) center;
        background-repeat: no-repeat;
        padding-right: 1.5rem;
        font-size: 0.85rem;
      }

      select {
        font-size: 0.85rem;
      }
    }
    .rt-tr {
      cursor: pointer;
    }
    .rt-td {
      font-size: 0.85rem;
      white-space: unset;
    }
  }
  .add-entry {
    margin-left: auto;
    margin-top: auto;
    height: 3rem;
  }
}
.loading-message {
  height: 3rem;
}

#job-logs.report-table {
  .ReactTable {
    .rt-tr {
      cursor: auto;
    }
  }
  .date-picker {
    display: inline;
    float: left;
  }
}
​

/** ADD JOB LOG PAGE STYLES **/
#add-job-log, .add-job-log {
  form {
    width: 50%;
    max-width: 650px;
    margin: 0 auto;
    text-align: left;
    &.full-width {
      width: 100%;
    }
    label {
      display: block;
      margin-bottom: 0;
    }
    .required {
      color: $danger;
    }
    .form {
      &-field {
        display: flex;
        .icon {
          cursor: pointer;
          margin-left: 0.5rem;
          height: 1rem;
          width: 1rem;
        }
      }
      &-select {
        flex-grow: 1;
      }
    }
  }
  .modal-form-field {
    display: flex;
    .form-group {
      width: 100%;
    }
    .icon-wrapper {
      display: flex;
      margin-top: 2rem;
      .icon {
        cursor: pointer;
        margin-left: 0.5rem;
        height: 1rem;
        width: 1rem;
      }
    }
  }
}

.role-wrapper {
  text-align: left;
  font-size: 1.2rem;
  width: 25rem;
  height: 9.2rem;
  .employee-disposal {
    font-size: 1rem;
    padding: none;
  }
}
.role-display {
  margin-bottom: .8rem;
}

.read-only-field {
  margin-bottom: .8rem;
}
