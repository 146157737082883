@import './variables.scss';

/** MODAL STYLES **/
.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 50vw;
  background: white;
  transform: translate(-50%, -50%);
  padding: 1.5rem 3rem;
  border-radius: 6px;

  .body {
    padding: 1rem;
  }

  &:focus {
    outline: none;
  }

  .close {
    position: absolute;
    top: 8px;
    right: 1rem;
    opacity: 0.5;
    cursor: pointer;
    padding: 0.5rem;
    img {
      height: 1rem;
      width: 1.25rem;
    }
  }

  .title {
    font-weight: 700;
    font-size: 1.4rem;
    text-align: center;
    text-transform: uppercase;
  }

  .actions {
    margin-top: 2rem;
    display: flex;
    justify-content: space-around;
    margin-left: -3rem;
    margin-right: -3rem;
    .btn {
      border: none;
      font-size: 1.25rem;
    }
    .muted {
      color: color(gray-3);
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}